import React from 'react';
import { Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const LandingPage = () => {
  return (
    <Container className="text-center mt-5">
      <h1 className="display-4">Site Under Construction</h1>
      <p className="lead">Crafting a website is not my forte... that's why I'm leaving it to the experts.</p>
      <Button 
        onClick={() => window.location.href = 'mailto:stageninja@gmail.com'}
        variant="primary" 
        className="mt-3"
      >
        Email Me
      </Button>
    </Container>
  );
};

export default LandingPage;
